import React from "react";
import AppsHero from "../../Assets/LandingDimensionalApps.svg";

const AppGallery = () => {
  return (
    <>
      <div className="showcase-image">
        <img src={AppsHero} />
      </div>
      <div className="showcase-text">
        tools & apps &#8212; built just for you.
      </div>
    </>
  );
};

export default AppGallery;
